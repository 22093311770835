<template>
  <ElDialog
    v-model="showModal"
    width="auto"
    class="formester-dialog ff-inter"
    :class="{
      'formester-dialog': true,
      'hide-header': !showHeader,
      'big-button': description,
    }"
    :style="{ width: `${props.width}px` }"
    :close-on-click-modal="closeOnClickAway"
    :show-close="false"
    :lock-scroll="false"
    modal
    :modal-class="blurBackground ? 'backdrop-blur' : ''"
    :align-center="alignCenter"
    append-to-body
    @closed="emits('click-away')"
    @opened="emits('opened')"
  >
    <template #header>
      <div class="d-flex align-items-start flex-column w-100">
        <div
          class="d-flex align-items-center justify-content-between w-100 gap-8"
        >
          <div class="d-flex align-items-center gap-8 w-75">
            <BackIcon
              v-if="showBackButton"
              :color="backIconHovered ? '#6434D0' : '#101828'"
              class="pointer"
              @click="emits('back')"
              @mouseover="backIconHovered = true"
              @mouseleave="backIconHovered = false"
            />
            <slot name="header_icon" />
            <!-- <component v-if="headerIcon" :is="getIconComponent" /> -->

            <h3 v-if="title" class="el-dialog__title">{{ title }}</h3>
          </div>

          <CrossIcon
            v-if="showCloseIcon"
            :color="crossIconHovered ? '#6434D0' : '#475467'"
            class="pointer"
            @click="close"
            @mouseover="crossIconHovered = true"
            @mouseleave="crossIconHovered = false"
          />
        </div>

        <p v-if="description" class="el-dialog__description">
          {{ description }}
        </p>
      </div>
    </template>

    <div :class="noPadding ? '' : 'padding-24'">
      <slot />
    </div>
  </ElDialog>
</template>

<script setup>
import { ref } from "vue";
import { ElDialog } from "element-plus";
import CrossIcon from "@/components/icons/cross-icon.vue";
import BackIcon from "@/components/icons/back-icon.vue";

defineExpose({ open, close });

const props = defineProps({
  showCloseIcon: {
    type: Boolean,
    default: true,
  },
  showBackButton: {
    type: Boolean,
    default: false,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  blurBackground: {
    type: Boolean,
    default: true,
  },
  closeOnClickAway: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: "400",
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  alignCenter: {
    type: Boolean,
    default: true,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["close", "back", "click-away", "opened"]);

const showModal = ref(false);
const backIconHovered = ref(false);
const crossIconHovered = ref(false);

function open() {
  showModal.value = true;
}

function close() {
  emits("close");
  showModal.value = false;
}
</script>

<style>
.formester-dialog {
  border-radius: 4px;
  background-color: var(--background-color-primary, #ffff);
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.formester-dialog .el-dialog__header {
  padding: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.formester-dialog.hide-header .el-dialog__header {
  padding: 0;
  margin: 0;
  border: none;
}

.formester-dialog .el-dialog__header .el-dialog__title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi-bold);
  line-height: 30px;
  margin: 0;
  min-width: fit-content;
}
.el-dialog__title {
  text-transform: capitalize;
}

.formester-dialog .el-dialog__header .el-dialog__description {
  margin: 0;
}

.formester-dialog .el-dialog__header .el-dialog__headerbtn {
  top: 2px;
}

.formester-dialog.big-button .el-dialog__header .el-dialog__headerbtn {
  top: 10px;
  right: 10px;
}

.formester-dialog.big-button
  .el-dialog__header
  .el-dialog__headerbtn
  .el-dialog__close {
  font-size: 1.3rem;
}

.formester-dialog .el-dialog__body {
  padding: 0;
  overflow-y: auto;
  max-height: calc(100vh - 120px);
}

.padding-24 {
  padding: 24px;
}

.backdrop-blur {
  backdrop-filter: blur(10px);
}

.gap-8 {
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .formester-dialog {
    width: 90% !important;
  }
}
</style>
